import { Injectable } from '@angular/core';
import {
	Router,
	CanActivate,
	NavigationCancel,
} from '@angular/router';
import { UserService } from '../services/user.service';
import { UrlService } from '../services/url.service';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard implements CanActivate {
	constructor(
		private userService: UserService,
		private router: Router,
		private us: UrlService,
	) {
		router.events.subscribe((event) => {
			if (event instanceof NavigationCancel) {
				this.us.setPreviousUrl(event.url);
			}
		});
	}

	canActivate(): boolean {
		if (!this.userService.token) {
			this.router.navigate(['auth/login']);
			return false;
		}
		return true;
	}
}
