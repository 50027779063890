import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NotFoundComponent } from './not-found/not-found.component';
import { AuthGuard } from './guards/auth-guard';

const routes: Routes = [
	{
		path: 't.php', // this is a v1 route we need to redirect to a proper handler in v2
		redirectTo: 'auth/login',
	},
	{
		path: '404',
		component: NotFoundComponent,
	},
	{
		path: 'auth',
		loadChildren: () =>
			import('../auth/auth.module').then((mod) => mod.AuthModule),
	},
	{
		path: 'management',
		loadChildren: () =>
			import('../management/management.module').then(
				(mod) => mod.ManagementModule,
			),
		canActivate: [AuthGuard],
	},
	{
		path: 'worker',
		loadChildren: () => import('../worker/worker.module').then((mod) => mod.WorkerModule),
		canActivate: [AuthGuard],
	},
	{
		path: '',
		loadChildren: () =>
			import('../public/public.module').then((mod) => mod.PublicModule),
	},
	{
		path: '**',
		component: NotFoundComponent,
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class CoreRoutingModule {}
