import {
	Component,
	ElementRef,
	NgZone,
	Renderer2,
	ViewChild,
	OnInit,
	OnDestroy,
	Injector
} from '@angular/core';
import {
	NavigationCancel,
	NavigationEnd,
	NavigationError,
	NavigationStart,
	Router,
	RouterEvent
} from '@angular/router';
import { NgwWowService } from 'ngx-wow';
import { Subscription } from 'rxjs';
import './core/utilities/nb-modals';
import { appInjector } from './core/utilities/app-injector';

const vh = window.innerHeight / 100;
document.documentElement.style.setProperty('--vh', `${vh}px`);

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
	// Instead of holding a boolean value for whether the spinner
	// should show or not, we store a reference to the spinner element,
	// see template snippet below this script
	@ViewChild('spinnerElement', { static: false, read: ElementRef })
	spinnerElement: ElementRef;
	browserSupported = false;
	subs: Subscription[] = [];

	constructor(
		private router: Router,
		private ngZone: NgZone,
		private renderer: Renderer2,
		private wowService: NgwWowService,
		private injector: Injector
	) {
		const sub = router.events.subscribe((event: RouterEvent) => {
			this._navigationInterceptor(event);

			if (event instanceof NavigationEnd) {
				this.wowService.init();
			}
		});

		this.subs.push(sub);

		appInjector(injector);
		this.getBrowser();
	}

	ngOnInit() {
		const sub = this.wowService.itemRevealed$.subscribe(
			(item: HTMLElement) => {}
		);
		this.subs.push(sub);
	}

	getBrowser() {
		const userAgent = navigator.userAgent.toLocaleLowerCase();
		if (userAgent.match(/opr\//i)) {
			this.browserSupported = false;
		} else if (
			userAgent.match(
				/SAMSUNG|Samsung|SGH-[I|N|T]|GT-[I|N]|SM-[A|N|P|T|Z]|SHV-E|SCH-[I|J|R|S]|SPH-L/i
			)
		) {
			this.browserSupported = false;
		} else if (userAgent.match(/firefox|fxios/i)) {
			this.browserSupported = true;
		} else if (userAgent.match(/safari/i)) {
			this.browserSupported = true;
		} else if (/chrome|chromium|crios/i) {
			this.browserSupported = true;
		} else if (userAgent.match(/edg/i)) {
			this.browserSupported = true;
		} else {
			this.browserSupported = false;
		}
	}

	onClose() {
		this.browserSupported = true;
	}

	ngOnDestroy() {
		// unsubscribe (if necessary) to WOW observable to prevent memory leaks
		this.subs.forEach(sub => sub.unsubscribe());
	}

	// Shows and hides the loading spinner during RouterEvent changes
	private _navigationInterceptor(event: RouterEvent): void {
		if (event instanceof NavigationStart) {
			// We wanna run this function outside of Angular's zone to
			// bypass change detection
			this.ngZone.runOutsideAngular(() => {
				// For simplicity we are going to turn opacity on / off
				// you could add/remove a class for more advanced styling
				// and enter/leave animation of the spinner
				this.renderer.setStyle(
					this.spinnerElement.nativeElement,
					'display',
					'flex'
				);
			});
		}
		if (event instanceof NavigationEnd) {
			this._hideSpinner();
			window.scrollTo(0, 0);
		}

		// Set loading state to false in both of the below events to
		// hide the spinner in case a request fails
		if (event instanceof NavigationCancel) {
			this._hideSpinner();
		}
		if (event instanceof NavigationError) {
			this._hideSpinner();
		}
	}

	private _hideSpinner(): void {
		// We wanna run this function outside of Angular's zone to
		// bypass change detection,
		this.ngZone.runOutsideAngular(() => {
			// For simplicity we are going to turn opacity on / off
			// you could add/remove a class for more advanced styling
			// and enter/leave animation of the spinner
			this.renderer.setStyle(
				this.spinnerElement.nativeElement,
				'display',
				'none'
			);
		});
	}
}
