import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { CoreRoutingModule } from './core-routing.module';
import { NotFoundComponent } from './not-found/not-found.component';

@NgModule({
	imports: [
		CommonModule,
		CoreRoutingModule,
		HttpClientModule,
	],
	declarations: [
		NotFoundComponent,
	],
	exports: [
		RouterModule,
	],
	providers: [],
})

export class CoreModule {

	constructor() {}

}
