import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { parseJwt } from '../utilities/jwt';

@Injectable({
	providedIn: 'root',
})
export class GlobalErrorInterceptor implements HttpInterceptor {
	constructor(private toastService: NbToastrService, private userService: UserService) {}

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler,
	): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			tap(
				() => {},
				(err: any) => {
					if (err instanceof HttpErrorResponse) {
						
						if (err.status === 500 || !err.error.err) {
							this.toastService.danger('Unexpected error, try again later...', 'Error!', {
								duration: 60000,
							});

							return;
						} else {
							if(err.status !== 401 || req.method !== 'GET') {
								this.toastService.danger(err.error.err, 'Error!', {
									duration: 60000,
								});
							}
						}
					}
				},
			),
		);
	}
}
