<div #header>
	<nav class="nav-header">
		<div class="nav-container" [ngClass]="{ open: open }">
			<div class="nav-logo">
				<img
					class="logo-laborjack"
					src="assets/public-photos/laborjack_logo_20220505.svg"
					[routerLink]="['/']"
				/>
			</div>
			<div class="nav-menu">
				<div class="wrapper">
					<ul class="nav-list">
						<li
							class="nav-item"
							*ngFor="let item of options"
							[ngClass]="{ 'toggle-menu': item.subMenu.length > 0 }"
						>
							<a
								[routerLink]="item.routerLink"
								(click)="toggleSubMenu(item.name)"
								>{{ item.text }}</a
							><img
								class="chevron"
								src="assets/header/chevron-down.svg"
								*ngIf="item.subMenu.length > 0"
							/>
							<div class="wrapper" *ngIf="item.subMenu.length > 0">
								<ul class="sub-menu">
									<li class="sub-menu-item" *ngFor="let option of item.subMenu">
										<a [routerLink]="option.routerLink"
											>{{ option.text }}
											<img
												class="icon-service"
												[src]="
													option.text === 'Moving'
														? 'assets/header/icon-moving.svg'
														: option.text === 'Staffing'
														? 'assets/header/icon-staffing.svg'
														: 'assets/header/icon-landscaping.svg'
												"
										/></a>
									</li>
								</ul>
							</div>
						</li>
						<li
							class="nav-item"
							*ngIf="!isLogged"
							[routerLink]="['/become-a-laborjack']"
						>
							<a>Apply</a>
						</li>
						<li class="nav-item">
							<a href="tel:(833)527-1185">(833)527-1185</a>
						</li>
					</ul>
				</div>
			</div>
			<div class="nav-button" [ngClass]="{ open: open }">
				<button
					class="btn btn-danger btn-book btn-gradient"
					*ngIf="bookButton && !open"
					[ngClass]="{ 'book-job': !active }"
					(click)="openBookingFlow()"
				>
					Book a Job</button
				><img
					class="icon-menu"
					*ngIf="!open"
					(click)="toggleMenu()"
					src="assets/header/icon-menu.svg"
				/><img
					class="icon-close"
					*ngIf="open"
					(click)="toggleMenu()"
					src="assets/header/icon-close.svg"
				/>
			</div>
			<div class="mobile-menu" [ngClass]="{ open: open }">
				<ul class="nav-menu">
					<li class="nav-item" *ngFor="let item of options">
						<div class="toogle-menu" (click)="toggleSubMenu(item.name)">
							<a [routerLink]="item.routerLink">{{ item.text }}</a
							><img
								class="chevron"
								src="assets/header/chevron-down.svg"
								[ngClass]="{ open: openSubMenu[item.name] }"
								*ngIf="item.subMenu.length > 0"
							/>
						</div>
						<ul
							class="sub-menu"
							[ngClass]="{ open: openSubMenu[item.name] }"
							*ngIf="item.subMenu.length > 0"
						>
							<li class="nav-item" *ngFor="let option of item.subMenu">
								<a
									[routerLink]="option.routerLink"
									(click)="toggleSubMenu(item.name); toggleMenu()"
									><img
										class="icon-service"
										[src]="
											option.text === 'Moving'
												? 'assets/header/icon-moving.svg'
												: option.text === 'Staffing'
												? 'assets/header/icon-staffing.svg'
												: 'assets/header/icon-landscaping.svg'
										"
									/>
									{{ option.text }}</a
								>
							</li>
						</ul>
					</li>
					<li
						class="nav-item"
						*ngIf="!isLogged"
						[routerLink]="['/become-a-laborjack']"
					>
						<a>Apply</a>
					</li>
					<li class="nav-item" *ngIf="!isLogged" [routerLink]="['/auth/login']">
						<a>Login</a>
					</li>
					<li class="nav-item" *ngIf="isLogged" [routerLink]="['/management']">
						<a>My Account</a>
					</li>
					<a
						class="btn-danger btn-book btn-gradient phone"
						href="tel:(833)527-1185"
						>(833)527-1185</a
					><a class="btn-danger btn-book btn-gradient" [routerLink]="['/book']"
						>Book a Job Online</a
					>
					<p class="trust-laborjack">
						Trust Laborjack <br />
						with your project.
					</p>
				</ul>
			</div>
		</div>
		<div class="nav-login">
			<a class="button-login" *ngIf="isLogged" [routerLink]="['/management']"
				>My Account</a
			><a class="button-login" *ngIf="!isLogged" [routerLink]="['/auth/login']"
				>Login</a
			>
		</div>
	</nav>
</div>
