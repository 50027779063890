import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';

@Injectable({
	providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
	constructor(public userService: UserService) {}
	intercept(
		request: HttpRequest<any>,
		next: HttpHandler,
	): Observable<HttpEvent<any>> {
		if(this.userService.token) {
			request = request.clone({
				setHeaders: {
					authorization: `Bearer ${this.userService.token}`,
				},
			});
		}
		return next.handle(request);
	}
}
