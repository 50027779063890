import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class UnauthorizedInterceptor implements HttpInterceptor {
	constructor(public userService: UserService, private router: Router) {}

	shouldKickOut(err: HttpErrorResponse) {
		const isInsideSystem = this.router.url.includes('/management') || this.router.url.includes('/worker');
		return this.userService.token && err.status === 401 && err.error.err && err.error.err === 'Not Authorized' && isInsideSystem;
	}

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler,
	): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			tap(() => {},
				(err: any) => {
					if (err instanceof HttpErrorResponse) {
						if(this.shouldKickOut(err)) {
							this.userService.removeUser();
							this.router.navigate(['/auth']);
						}
					}
				},
			),
		);
	}
}
