import { Component, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BaseComponent } from '../../../library/classes/base.component';
import { AuthService } from '../../auth.service';
import { mapValues } from 'lodash';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { UserService } from '../../../core/services/user.service';
import { UrlService } from '../../../core/services/url.service';
import { environment } from 'src/environments/environment';
import 'rxjs/add/operator/zip';

type Modes = 'login' | 'forgot-password' | 'forgot-sent' | 'forgot-failed';

interface RouteData {
	mode: Modes;
	email?: string;
}

interface RouteParams {
	token: string;
}

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseComponent implements OnDestroy {
	public loading = false;
	public mode: Modes = 'login';
	public token = '';
	temporalEmail = '';
	private subscriptions: Subscription[] = [];
	private previousUrl: string;
	public form = new FormGroup({
		email: new FormControl('', [Validators.required, Validators.email]),
		password: new FormControl('', [Validators.required])
	});

	constructor(
		private authService: AuthService,
		private route: ActivatedRoute,
		private router: Router,
		private userService: UserService,
		private uService: UrlService,
	) {
		super();

		this.subscriptions.push(
			this.route.params.zip(this.route.data).subscribe(data => {
				const routeParams = data[0] as RouteParams;
				const dataParams = data[1] as RouteData;

				if (dataParams.mode === 'login' && routeParams.token) {
					this.loginWithToken(routeParams.token);
				} else {
					this.subscriptions.push(
						this.userService.temporalEmail$.subscribe(
							(temporalEmail: string) => {
								this.temporalEmail = temporalEmail;
							}
						)
					);
					this.mode = dataParams.mode;
					const setValue = {
						email: this.temporalEmail,
						password: ''
					};

					if (this.mode === 'forgot-password') {
						setValue.email = this.temporalEmail;
						setValue.password = 'placeholder';
					}

					this.form.setValue(setValue);
				}
			})
		);
		this.subscriptions.push(
			this.uService.previousUrl$.subscribe((previousUrl: string) => {
				this.previousUrl = previousUrl;
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	get f() {
		return this.form.controls;
	}

	getTitle(): string {
		switch (this.mode) {
			case 'login':
				return 'Laborjack Login.';
			case 'forgot-password':
			case 'forgot-sent':
				return 'Forgot Password';
			default:
				return 'login';
		}
	}

	getSubmitTitle(): string {
		switch (this.mode) {
			case 'login':
				return 'Login';
			case 'forgot-password':
			case 'forgot-sent':
				return 'Send Link';
			default:
				return 'Login';
		}
	}

	saveEmail(email: string) {
		if (email) {
			this.userService.setEmail(email);
		}
	}

	async submit() {
		if (this.mode === 'login') {
			this.submitLogin();
		} else if (this.mode === 'forgot-password') {
			this.submitForgotPassword();
		}
	}

	async submitLogin() {
		const model = mapValues(this.f, v => v.value);
		try {
			this.loading = true;
			const response = await this.authService.login(
				model.email,
				model.password
			);
			this.loading = false;
			this.userService.saveToken(response.id_token);
			const user = await this.userService.fetchUser();

			if (this.previousUrl) {
				const urlParams = new URL(environment.frontEndpoint + this.previousUrl);
				const paramsArray = {};
				urlParams.searchParams.forEach((value, key) => {
					paramsArray[key] = value;
				});
				if (user.role === 'admin') {
					if (this.previousUrl.toLowerCase().includes('management')) {
						return this.router.navigate([urlParams.pathname], {
							queryParams: paramsArray
						});
					}

					this.router.navigate(['management']);
				} else if (user.role === 'worker') {
					if (this.previousUrl.toLowerCase().includes('worker')) {
						return this.router.navigate([urlParams.pathname], {
							queryParams: paramsArray
						});
					}
					this.router.navigate(['worker']);
				}
			} else {
				if (user.role === 'admin') {
					this.router.navigate(['management']);
				} else if (user.role === 'worker') {
					this.router.navigate(['worker']);
				}
			}
		} catch (err) {
			this.loading = false;
			return this.userService.removeUser();
		}
	}

	async submitForgotPassword() {
		const model = mapValues(this.f, v => v.value);
		try {
			this.loading = true;
			await this.authService.forgotPassword(model.email);
			this.loading = false;
			this.mode = 'forgot-sent';
		} catch (err) {
			this.loading = false;
			if (err.status === 404) {
				// no-op don't want to let the UI probe for email addresses
				this.mode = 'forgot-sent';
				return;
			}
		}
	}

	async loginWithToken(token) {
		try {
			this.loading = true;
			this.userService.removeUser();
			const response = await this.authService.loginWithToken(token);
			this.loading = false;
			this.userService.saveToken(response.id_token);

			const user = await this.userService.fetchUser();
			this.userService.saveUser(user);

			if (response.redirect || response.target) {
				let url = response.redirect || response.target.url;

				if (typeof url !== 'string') {
					url = '/';
				}
				if (url.match(/^\//)) {
					await this.router.navigateByUrl(url); // .navigate() applies URI encoding. .navigateByUrl() does not re-encode the URL
				} else {
					window.location.href = url; // rare case, but let's handle it
				}
			} else {
				if (user.role === 'admin') {
					this.router.navigate(['management']);
				} else if (user.role === 'worker') {
					this.router.navigate(['worker']);
				}
			}
		} catch (err) {
			this.loading = false;
		}
	}
}
