import JwtPayload from '../models/JwtPayload';

function parseJwt(token): JwtPayload {
	try {
		return JSON.parse(atob(token.split('.')[1]));
	} catch (e) {
		return null;
	}
}

export { parseJwt };
