import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
@Component({
	template: ''
})
export class BaseComponent implements OnInit, OnDestroy {
	protected ngDestroyed = new Subject<void>();

	constructor() {}

	onInit() {
		// replaces ngOnInit in angular components
	}

	onDestroy() {
		// replaces ngOnDestroy in angular components
	}

	ngOnInit() {
		// execute what's in the init() function
		this.onInit.apply(this, arguments);
	}

	ngOnDestroy() {
		// execute what's in the destroy() function
		this.onDestroy.apply(this, arguments);

		// unsubscribe to ensure no memory leaks
		this.ngDestroyed.next();
		this.ngDestroyed.unsubscribe();
	}
}
