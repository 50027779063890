<nb-alert
	class="alert-browser"
	*ngIf="!browserSupported"
	status="info"
	closable="closable"
	(close)="onClose()"
>
	<div class="browser">
		<p>Browser not supported, we recommend using one of these browser.</p>
		<div class="browser-icons">
			<a href="https://www.google.com/intl/es-419/chrome/"
				><img src="assets/icons/chrome-icon.png" /></a
			><a href="https://support.apple.com/downloads/safari"
				><img src="assets/icons/safari-icon.png" /></a
			><a href="https://www.microsoft.com/es-es/edge"
				><img src="assets/icons/edge-icon.png" /></a
			><a href="https://www.mozilla.org/es-MX/firefox/new/"
				><img src="assets/icons/firefox-icon.png"
			/></a>
		</div>
	</div>
</nb-alert>
<router-outlet></router-outlet>
<div class="loading" #spinnerElement>
	<img src="assets/loading.svg" />
</div>
