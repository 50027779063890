import { SafeUrl } from '@angular/platform-browser';
import JobModel from '../models/JobModel';
import { format, addHours, addMinutes } from 'date-fns';

export function arr_diff( a1: Array<any>, a2: Array<any> ) {
	const exist = {};

	a1.forEach(v => {
		exist[v] = true;
	});

	a2.forEach(v => {
		if (exist[v]) {
			delete exist[v];
		} else {
			exist[v] = true;
		}
	});

	return Object.keys(exist);
}

export function safeImage( url: string ) {
	return this.sanitizer.bypassSecurityTrustUrl(
		url,
	);
}

export function getAddress( job: JobModel ): string {

	if (!job.addresses || job.addresses.length < 1) {
		return 'No Address';
	}

	if (job.markets && job.markets.length > 0) {
		return job.markets.map(m => m.name).join(', ');
	}

	const address = job.addresses[0];

	return `${ address.city }, ${ address.state } ${ address.zip }`;
}

export function getDate( job: JobModel, includeYear: boolean = false ): string {

	const a = (job.jobDateTime ? job.jobDateTime.split(/[^0-9]/) : `${new Date().getFullYear()}-01-01 00:00:00`.split(/[^0-9]/));
	const date = new Date(+a[0], +a[1] - 1, +a[2], +a[3], +a[4], +a[5]);

	let formatString = 'eeee - MMMM do';

	if (includeYear) {
		formatString += ', yyyy';
	}

	return format(
		date,
		formatString,
	);
}

export function getTime( job: JobModel ): string {
	const a = (job.jobDateTime ? job.jobDateTime.split(/[^0-9]/) : `${new Date().getFullYear()}-01-01 00:00:00`.split(/[^0-9]/));
	const date = new Date(+a[0], +a[1] - 1, +a[2], +a[3], +a[4], +a[5]);
	const minutes = (job.hours - Math.floor(job.hours)) * 60;
	let endDate = addHours(date, Math.floor(job.hours));
	endDate = addMinutes(endDate, minutes);

	const startTime = format(
		date,
		'hh:mm a',
	);

	const endTime = format(
		endDate,
		'hh:mm a',
	);

	return `${ startTime } - ${ endTime }`;
}


export function toBase64Url( file: File ): Promise<string> {
	return new Promise(( resolve, reject ) => {
		const reader = new FileReader();

		reader.onloadend = () => {
			const imageUrl = reader.result.toString();

			resolve(imageUrl);
		};

		reader.readAsDataURL(file);
	});
}

export function formatSSN( ssn: string ) {
	const cleaned = (ssn).replace(/\D/g, '');

	let newSSN = '';

	const first = cleaned.slice(0, 3);

	if (first) {
		newSSN += first;
		if (cleaned.length > 3) {
			newSSN += '-';
		}
	}

	const second = cleaned.slice(3, 5);

	if (second) {
		newSSN += second;
	}

	const third = cleaned.slice(5, 9);

	if (third) {
		newSSN += '-' + third;
	}

	return newSSN;
}

export function formatPhoneNumber( phoneNumber: string ) {
	const cleaned = (phoneNumber).replace(/\D/g, '');

	let newPhoneNumber = '';

	const first = cleaned.slice(0, 3);

	if (first) {
		newPhoneNumber += '(' + first;
		if (cleaned.length > 3) {
			newPhoneNumber += ')';
		}
	}

	const second = cleaned.slice(3, 6);

	if (second) {
		newPhoneNumber += second;
	}

	const third = cleaned.slice(6, 10);

	if (third) {
		newPhoneNumber += '-' + third;
	}

	return newPhoneNumber;
}

export const copyToClipboard = ( str ) => {
	return new Promise<void>(( resolve, reject ) => {
		try {
			const el = document.createElement('textarea');
			el.value = str;
			document.body.appendChild(el);
			el.select();
			document.execCommand('copy');
			document.body.removeChild(el);

			resolve();
		} catch (error) {
			reject(error);
		}
	});
};
