import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class UrlService {
	private previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);
	public previousUrl$: Observable<string> = this.previousUrl.asObservable();

	setPreviousUrl(previousUrl: string) {
		this.previousUrl.next(previousUrl);
	}
}