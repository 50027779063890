import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './auth.component';
import { LoginComponent } from './pages/login/login.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';

const routes: Routes = [
	{
		path: '',
		component: AuthComponent,
		children: [
			{
				path: '',
				pathMatch: 'full',
				redirectTo: 'login'
			},
			{
				path: 'login',
				component: LoginComponent,
				data: {
					mode: 'login'
				}
			},
			{
				path: 'login/:token', // default route for all app logins
				component: LoginComponent,
				data: {
					mode: 'login'
				}
			},
			{
				path: 'worker/login/:token', // a worker specific login route that can be hooked by the worker mobile app for deep linking
				component: LoginComponent,
				data: {
					mode: 'login'
				}
			},
			{
				path: 'forgot-password',
				component: LoginComponent,
				data: {
					mode: 'forgot-password'
				}
			},
			{
				path: 'change-password/:token',
				component: ChangePasswordComponent
			}
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class AuthRoutingModule {}
