import { Component, OnInit } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { environment } from 'src/environments/environment';
import { copyToClipboard } from 'src/app/core/utilities';
import { ConstantsService } from 'src/app/core/services/constant.service';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
	links: any[] = [
		{ text: 'Services', routerLink: ['/'] },
		{ text: 'Locations', routerLink: ['/locations'] },
		{ text: 'About Us', routerLink: ['/about-us'] },
		{ text: 'Blog', url: 'https://laborjack.com/blog' },
		{
			text: 'Become a Laborjack',
			routerLink: ['/apply']
		},
		{ text: 'Login', routerLink: ['/auth'] },
		{
			text: 'Book a Job',
			url: environment.customerBookingFlowUrl
		},
		{ text: 'News', routerLink: ['/news'] }
	];
	link: any;
	sitemap = environment.sitemap;
	sitemapXML = environment.sitemapXML;
	termsOfService = environment.contracts.termsOfService;
	privacyPolicy = environment.contracts.privacyPolicy;
	bookingFlowUrl = environment.customerBookingFlowUrl;

	constructor(
		public constant: ConstantsService,
		private nbToastService: NbToastrService
	) {}

	clickVersion(e) {
		copyToClipboard(e.target.innerHTML).then(() => {
			this.nbToastService.success('Version copied to clipboard!', 'Success!');
		});
	}

	ngOnInit(): void {}
}
