import { Component, OnInit } from '@angular/core';
import { NbThemeService } from '@nebular/theme';

@Component({
	selector: 'app-auth',
	templateUrl: './auth.component.html',
	styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
	constructor(private themeService: NbThemeService) {
		this.themeService.changeTheme('default');
	}

	ngOnInit(): void {}
}
