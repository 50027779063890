<img class="logo"
	  src="assets/public-photos/laborjack_logo_20220505.svg" />
<nb-card class="login fadeIn">
	<nb-card-header>
		<h4>Choose a New Password</h4>
	</nb-card-header>
	<nb-card-body *ngIf="error">
		<div class="message">{{ error }}</div><a class="link"
			[routerLink]="'../../forgot-password'">Try again</a><br /><a class="link"
			[routerLink]="'../../login'">Back to login</a>
	</nb-card-body>
	<nb-card-body *ngIf="complete">
		<div class="message"><strong>Success!</strong> Your password has been updated.</div><a class="link"
			[routerLink]="'../../login'">Back to login</a>
	</nb-card-body>
	<nb-card-body *ngIf="!complete && !error">
		<form [formGroup]="form"
				(ngSubmit)="submit()">
			<div class="form-group"><label class="form-label">Password</label><input nbInput
						 fullWidth
						 type="password"
						 formControlName="password"
						 [status]="f.password.dirty && f.password.invalid  ? 'danger' : 'basic'" />
				<div *ngIf="f['password'].errors && !f['password'].pristine else noError">
					<div class="text-danger"
						  [hidden]="!f['password'].errors.required">Password is required.</div>
				</div>
			</div>
			<div class="form-group"><label class="form-label">Confirm Password</label><input nbInput
						 fullWidth
						 type="password"
						 formControlName="confirmPassword"
						 [status]="f.confirmPassword.dirty && f.confirmPassword.invalid  ? 'danger' : 'basic'" />
				<div *ngIf="f['confirmPassword'].errors && !f['confirmPassword'].pristine else noError">
					<div class="text-danger"
						  [hidden]="!f['confirmPassword'].errors.required">Password is required.</div>
				</div>
				<div *ngIf="!checkPasswords() else noError">
					<div class="text-danger">Passwords do not match.</div>
				</div>
			</div><button class="btn-login"
					  nbButton
					  status="danger"
					  [disabled]="form.invalid || !checkPasswords()">Submit</button>
		</form>
	</nb-card-body>
</nb-card>
<ng-template #noError>
	<div>&nbsp;</div>
</ng-template>