import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import User from '../models/User';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import Payload from '../models/Payload';
import { map } from 'rxjs/operators';
import LoginResponse from '../models/LoginResponse';
import ModerateModel from '../models/ModerateModel';

interface UpdatedPayload {
	updated: boolean;
}

@Injectable({
	providedIn: 'root'
})
export class UserService {
	private privateUser: BehaviorSubject<User> = new BehaviorSubject<User>(null);
	private userValue: User;
	endpoint = `${environment.apiEndpoint}/me`;
	stopInterval: any;

	private temporalEmail: BehaviorSubject<string> = new BehaviorSubject<string>(
		null
	);
	public temporalEmail$: Observable<string> = this.temporalEmail.asObservable();

	setEmail(temporalEmail: string) {
		this.temporalEmail.next(temporalEmail);
	}

	private startRefreshing() {
		this.stopInterval = setInterval(
			this.refreshToken,
			60000 * environment.refreshTokenTimeInMinutes
		);
	}

	private refreshToken = async () => {
		const { data } = await this.http
			.put<Payload<LoginResponse>>(`${environment.apiEndpoint}/auth/login`, {})
			.toPromise();
		this.saveToken(data.id_token);
	};

	private stopRefreshing() {
		clearInterval(this.stopInterval);
	}

	constructor(private http: HttpClient) {
		this.privateUser.next(null);
	}

	public saveUser(user: User) {
		this.privateUser.next(user);
	}

	public get currentUser(): Observable<User> {
		return this.privateUser.asObservable();
	}

	public get token(): string {
		return localStorage.getItem('token');
	}

	public removeMarket(user: User, marketId: number) {
		return this.http
			.delete(`${environment.apiEndpoint}/user/${user.id}/market/${marketId}`)
			.toPromise();
	}

	public assignMarket(user: User, marketId: number) {
		return this.http
			.put(`${environment.apiEndpoint}/user/${user.id}/market/${marketId}`, {})
			.toPromise();
	}

	public fetchUser(): Promise<User> {
		return this.http
			.get<Payload<User>>(`${this.endpoint}`)
			.pipe<User>(map(p => p.data))
			.toPromise()
			.then<User>(user => {
				this.privateUser.next(user);
				this.userValue = user;
				this.startRefreshing();

				this.identifyUser(user);

				return user;
			});
	}

	public identifyUser(user: User = null) {
		if (!user || typeof user.email !== 'string') {
			return;
		}
		const email = user.email.trim().toLowerCase();

		setTimeout(() => {
			// in case this is triggered right on page load, give a little extra time for smartlook to load

			const smartlook = (window as any).smartlook;

			if (!smartlook) {
				console.error('smartlook not loaded for identity');
				return;
			}

			const identity = {
				name: `${user.firstName || ''} ${user.lastName || ''}`.trim(),
				phone: user.phoneNumber || null,
				email,
				role: user.role || 'anonymous'
			};

			smartlook('identify', user.id || email, identity);

		}, 500);

		setTimeout(() => {
			// in case this is triggered right on page load, give a little extra time for smartlook to load

			const _hsq = ((window as any)._hsq = (window as any)._hsq || []);

			if (!_hsq) {
				console.error('hubspot not loaded for identity');
				return;
			}

			const identity = {
				firstname: user.firstName || '',
				lastname: user.lastName || '',
				phone: user.phoneNumber || null,
				email,
				role: user.role || 'anonymous'
			};

			_hsq.push(['identify', identity]);

		}, 500);
	}

	public putUser(user: User, saveUser = true): Promise<User> {
		return this.http
			.put<Payload<UpdatedPayload>>(`${this.endpoint}`, user)
			.toPromise()
			.then<User>(r => {
				if (r.data.updated) {
					this.userValue = { ...this.userValue, ...user };
					if (saveUser) {
						this.privateUser.next(this.userValue);
					}

					this.identifyUser(this.userValue);

					return user;
				}
			});
	}

	public changePassword(
		oldPassword: string,
		newPassword: string
	): Promise<any> {
		return this.http
			.put(`${environment.apiEndpoint}/auth/update-password`, {
				newPassword,
				oldPassword
			})
			.toPromise();
	}

	public getHistory(page, size): Promise<any> {
		return this.http
			.get(`${this.endpoint}/jobs?page=${page}&size=${size}`)
			.toPromise();
	}

	public saveToken(token: string) {
		localStorage.setItem('token', token);
	}

	public removeUser() {
		localStorage.removeItem('token');
		this.stopRefreshing();
		this.privateUser.next(null);

		// when a user logs out, just have a fresh start
		setTimeout(() => {
			this.identifyUser();
		}, 100);
	}

	public userHistory(user: User, page, size): Promise<any> {
		return this.http
			.get(
				`${environment.apiEndpoint}/user/${user.id}/jobs?page=${page}&size=${size}`
			)
			.toPromise();
	}

	public userReferral(user: User, page, size): Promise<any> {
		return this.http
			.get(
				`${environment.apiEndpoint}/user/${user.id}/referrals?page=${page}&size=${size}`
			)
			.toPromise();
	}

	public approveModerate(userId: number, user: User): Promise<any> {
		return this.http
			.put<Payload<ModerateModel>>(
				`${environment.apiEndpoint}/user/${userId}`,
				user
			)
			.toPromise();
	}

	public denyModerate(userId: number): Promise<any> {
		return this.http
			.put(`${environment.apiEndpoint}/user/${userId}/moderate`, {
				approve: false
			})
			.toPromise();
	}
}
