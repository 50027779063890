<nb-card class="login fadeIn">
    <nb-card-header>
        <h4 class="title text-center">{{ getTitle() }}</h4>
        <h4 class="subtitle text-center"
            *ngIf="mode === 'login'">Let's get to work!</h4>
    </nb-card-header>
    <nb-card-body *ngIf="mode === 'forgot-sent'">
        <div class="sent"><strong>Success!</strong> If an email address is found, an email will be sent with a link to
            reset your password.</div><a class="link"
           [routerLink]="'../login'">Back to login</a>
    </nb-card-body>
    <nb-card-body *ngIf="mode === 'login' || mode === 'forgot-password'"
                  [nbSpinner]="loading">
        <form [formGroup]="form"
              (ngSubmit)="submit()">
            <div class="form-group"><label class="form-label">Email</label><input nbInput
                       fullWidth
                       formControlName="email"
                       data-cy="email"
                       (keyup)="saveEmail(f.email.value)"
                       [status]="f.email.dirty && f.email.invalid  ? 'danger' : 'basic'" />
                <div *ngIf="f['email'].errors && !f['email'].pristine else noError">
                    <div class="text-danger"
                         [hidden]="!f['email'].errors.required">Email is required.</div>
                    <div class="text-danger"
                         [hidden]="!f['email'].errors.email">Invalid email.</div>
                </div>
            </div>
            <div class="form-group"
                 *ngIf="mode === 'login'"><label class="form-label">Password</label><input nbInput
                       fullWidth
                       type="password"
                       formControlName="password"
                       data-cy="password"
                       [status]="f.password.dirty && f.password.invalid  ? 'danger' : 'basic'" />
                <div *ngIf="f['password'].errors && !f['password'].pristine else noError">
                    <div class="text-danger"
                         [hidden]="!f['password'].errors.required">Password is required.</div>
                </div>
            </div><a class="link"
               *ngIf="mode === 'login'"
               [routerLink]="'../forgot-password'">Forgot your password?</a><a class="link"
               *ngIf="mode === 'forgot-password'"
               [routerLink]="'../login'">Back to login</a><button class="btn-login"
                    nbButton
                    status="danger"
                    [disabled]="form.invalid"
                    data-cy="submit"> {{ getSubmitTitle() }}</button>
        </form>
    </nb-card-body>
</nb-card>
<ng-template #noError>
    <div>&nbsp;</div>
</ng-template>