import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { parseJwt } from '../utilities/jwt';
import { NbToastrService } from '@nebular/theme';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class ExpiredTokenInterceptor implements HttpInterceptor {
	constructor(
		public userService: UserService,
		private toastrService: NbToastrService,
		private router: Router
	) {}

	checkExpired() {
		const payload = parseJwt(this.userService.token);
		if (this.userService.token && payload && payload.exp < Date.now()) {
			this.userService.removeUser();
			this.router.navigate(['/auth']);
		}
	}

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			tap(
				() => {},
				(err: any) => {
					if (err instanceof HttpErrorResponse) {
						if (err.status !== 401) {
							return;
						}

						this.checkExpired();
					}
				}
			)
		);
	}
}
