<footer class="footer">
	<div class="section-logos">
		<div class="wrapper">
			<a
				href="https://www.businessinsider.com/laborjack-colorado-labor-shortage-wage-find-workers-gig-economy-staff-2021-7"
				target="_blank"
				><img src="assets/footer/business-insider.png" /></a
			><a
				href="https://video.foxbusiness.com/v/6279247578001#sp=show-clips"
				target="_blank"
				><img src="assets/footer/fox-business.png" /></a
			><a
				href="https://www.cobizmag.com/laborjack-excels-with-%E2%80%A8muscle-for-hire/"
				target="_blank"
				><img src="assets/footer/colorado-biz.png" /></a
			><a
				href="https://www.latimes.com/business/story/2020-12-05/jobs-for-virtual-assistants-caregivers-people-muscles"
				target="_blank"
				><img src="assets/footer/los-angeles-time.png" /></a
			><a
				href="https://www.yahoo.com/now/laborjack-expands-5-star-muscle-150000855.html"
				target="_blank"
				><img src="assets/footer/yahoo-small-business.png"
			/></a>
		</div>
	</div>
	<div class="content">
		<div class="section-book">
			<div class="section-book-wrapper">
				<a href="{{ bookingFlowUrl }}" style="text-decoration: none">
					<img src="assets/footer/muscle-for-hire.png" /><a
						class="btn"
						nbButton
						filled
						status="danger"
						>Book a Job</a
					>
				</a>
			</div>
		</div>
		<div class="section-menu">
			<div class="section-menu-wrapper">
				<div class="section-menu__title">Discover</div>
				<ul class="section-menu__list">
					<li class="section-menu__list-item" *ngFor="let link of links">
						<a
							class="section-menu__list-item-link"
							*ngIf="link.routerLink"
							[routerLink]="link.routerLink"
							>{{ link.text }}</a
						><a
							class="section-menu__list-item-link"
							*ngIf="link.url"
							[href]="link.url"
							>{{ link.text }}</a
						>
					</li>
				</ul>
			</div>
			<div class="section-menu-wrapper">
				<div class="section-menu__title">Connect</div>
				<ul class="section-menu__list">
					<li class="section-menu__list-item">
						<a class="section-menu__list-item-link" href="tel:(833)527-1185"
							>(833) 527-1185</a
						>
					</li>
					<li class="section-menu__list-item">
						<a
							class="section-menu__list-item-link mail"
							href="mailto:support@laborjack.com"
							>support@laborjack.com</a
						>
					</li>
					<div class="social-images">
						<a
							class="section-menu__list-item-link"
							href="https://www.google.com/search?q=laborjack+fort+collins&amp;rlz=1C5CHFA_enUS795US795&amp;oq=laborjack+fort+collins&amp;aqs=chrome.0.69i59j46j0j69i60l5.3874j0j7&amp;sourceid=chrome&amp;ie=UTF-8#lrd=0x87694b8c7c2a1d25:0x2446bc44ec904a85,1,,"
							target="_blank"
							><img src="assets/footer/social-google.png" /></a
						><a
							class="section-menu__list-item-link"
							href="https://www.facebook.com/laborjack"
							target="_blank"
							><img src="assets/footer/social-facebook.svg" /></a
						><a
							class="section-menu__list-item-link"
							href="https://www.instagram.com/laborjack"
							target="_blank"
							><img src="assets/footer/social-instagram.png"
						/></a>
					</div>
				</ul>
			</div>
			<div class="section-menu-wrapper">
				<div class="section-menu__title">More</div>
				<ul class="section-menu__list">
					<li class="section-menu__list-item">
						<a
							class="section-menu__list-item-link"
							[href]="termsOfService"
							target="_blank"
							>Terms of Service</a
						>
					</li>
					<li class="section-menu__list-item">
						<a
							class="section-menu__list-item-link"
							[href]="privacyPolicy"
							target="_blank"
							>Privacy Policy</a
						>
					</li>
					<li class="section-menu__list-item">
						<a class="section-menu__list-item-link" routerLink="/faq">FAQ</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
	<div class="section-copyright">
		<p>
			Laborjack, LLC, © 2022 All Rights Reserved |
			<a [href]="sitemapXML" target="_blank">XML Sitemap</a> |
			<a [href]="sitemap" target="_blank">Sitemap</a> |
			<span class="version" (click)="clickVersion($event)"
				>UI: {{ constant.appVersion }} API:
				{{ constant.apiVersion | async }}</span
			>
		</p>
	</div>
</footer>
