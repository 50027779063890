import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { NbIconModule } from '@nebular/theme';
import { RouterModule } from '@angular/router';

@NgModule({
	declarations: [HeaderComponent],
	imports: [CommonModule, NbIconModule, RouterModule],
	exports: [HeaderComponent],
})
export class HeaderModule {}
