import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthComponent } from './auth.component';
import { AuthRoutingModule } from './auth-routing.component';
import { LoginComponent } from './pages/login/login.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';

import {
	NbButtonModule,
	NbCardModule,
	NbInputModule,
	NbLayoutModule,
	NbSpinnerModule,
} from '@nebular/theme';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthService } from './auth.service';
import { HeaderModule } from '../public/components/header/header.module';
import { FooterModule } from '../public/components/footer/footer.module';

@NgModule({
	declarations: [
		AuthComponent,
		ChangePasswordComponent,
		LoginComponent,
	],
	imports: [
		AuthRoutingModule,
		CommonModule,
		HeaderModule,
		FooterModule,
		NbButtonModule,
		NbCardModule,
		NbInputModule,
		NbLayoutModule,
		NbSpinnerModule,
		ReactiveFormsModule,
	],
	providers: [ AuthService ],
})
export class AuthModule {
}
