// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	appVersion: require('../../package.json').version,
	apiVersion: null,
	app: {
		worker: {
			expireDate: '2022-07-05'
		}
	},
	production: true,
	apiEndpoint: 'https://api.laborjack.com/v1',
	refreshTokenTimeInMinutes: 60,
	stripeClientId: 'ca_ApQw5LnCd78sgGD0qNmY6WbGz9H5IUYx',
	googleMaps: {
		key: 'AIzaSyBuyT1h23X-QZIg-O9HkjJoitvOx8ght6Q'
	},
	frontEndpoint: 'https://laborjack.com',
	calendlyLink:
		'https://calendly.com/laborjack/welcome-call?hide_event_type_details=1&text_color=757474&primary_color=d45333',
	vimeoLinks: {
		aboutUs: 'https://player.vimeo.com/video/460719649',
		becomeLaborjack: 'https://player.vimeo.com/video/470839343',
		laborjackInfo: 'https://player.vimeo.com/video/497782386'
	},
	contracts: {
		termsOfService:
			'https://laborjack.com/assets/documents/terms_of_service.pdf',
		privacyPolicy: '/privacy-policy',
		baseUrl: '/assets/documents/contracts',
		files: {
			independentContractorAgreement: {
				name: 'independent-contractor-agreement.pdf',
				version: 'v2'
			},
			waiverOfLiabilityAndHoldHarmless: {
				name: 'waiver-of-liability.pdf',
				version: 'v2'
			}
		}
	},
	customerBookingFlowUrl: 'https://laborjack.com/bookjob/index.html',
	sitemap: 'https://laborjack.com/sitemap.html',
	sitemapXML: 'https://laborjack.com/sitemap.xml'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
