import {
	Component,
	OnInit,
	HostListener,
	Input,
	ViewChild,
	ElementRef,
	AfterViewInit
} from '@angular/core';
import { Subject } from 'rxjs';
import Headroom from 'headroom.js';
import { UserService } from '../../../core/services/user.service';
import User from 'src/app/core/models/User';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/library/classes/base.component';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BaseComponent implements AfterViewInit {
	@Input() bookMobile = true;
	@Input() bookButton = true;
	@ViewChild('header') header: ElementRef;

	options: any[];
	item: any;
	openSubMenu = {};
	active = true;
	userActivity;
	userInactive: Subject<any> = new Subject();
	open = false;
	user: User;
	bookingFlowUrl = environment.customerBookingFlowUrl;

	constructor(private userService: UserService) {
		super();
		this.setTimeout();
		this.userInactive
			.pipe(takeUntil(this.ngDestroyed))
			.subscribe(() => (this.active = false));
		this.options = [
			{
				text: 'Services',
				name: 'services',
				routerLink: [],
				subMenu: [
					{
						text: 'Moving',
						routerLink: ['/services/moving']
					},
					{
						text: 'Landscaping',
						routerLink: ['/services/landscaping']
					},
					{
						text: 'Staffing',
						routerLink: ['/services/staffing']
					}
				]
			},
			{
				text: 'Locations',
				name: 'locations',
				routerLink: ['/locations'],
				subMenu: []
			}
		];

		this.userService.currentUser
			.pipe(takeUntil(this.ngDestroyed))
			.subscribe(user => {
				if (!user && this.userService.token) {
					this.userService
						.fetchUser()
						.catch(() => this.userService.removeUser());
				}
				this.user = user;
			});
	}

	get isLogged() {
		return !!this.user;
	}

	toggleSubMenu(key: string) {
		this.openSubMenu[key] = !this.openSubMenu[key];
	}

	ngAfterViewInit(): void {
		const headroom = new Headroom(this.header.nativeElement);

		headroom.init();
	}

	toggleMenu() {
		this.open = !this.open;
	}

	setTimeout() {
		this.userActivity = setTimeout(
			() => this.userInactive.next(undefined),
			60000
		);
	}

	refreshState() {
		clearTimeout(this.userActivity);
		this.active = true;
		this.setTimeout();
	}

	openBookingFlow() {
		window.open(this.bookingFlowUrl, '_self');
	}

	@HostListener('window:mousemove') mouseover() {
		this.refreshState();
	}
	@HostListener('window:scroll') scroll() {
		this.refreshState();
	}

	onInit(): void {}
}
