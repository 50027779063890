import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';


@Injectable()
export class ConstantsService {
	appVersion = environment.appVersion;
	apiVersion: BehaviorSubject<number> = new BehaviorSubject<number>(0);

	private propertiesSet = false;

	private endpoint = `${ environment.apiEndpoint }`;

	constructor( private http: HttpClient ) {
		this.getVersion();
	}


	private getVersion() {
		return this.http
			.get(`${ this.endpoint.replace('/v1', '') }/status`)
			.toPromise().then(( payload: any ) => {
				this.apiVersion.next(payload.version);

				return payload.version;
			})
			.then(apiVersion => {

				setTimeout(() => { // in case this is triggered right on page load, give a little extra time for smartlook to load

					if (this.propertiesSet) {
						return;
					}

					const smartlook = (window as any).smartlook;

					if (!smartlook) {
						console.error('smartlook not loaded for properties');
						return;
					}

					this.propertiesSet = true;
					smartlook('properties', {
						uiVersion: this.appVersion,
						apiVersion,
					});

				}, 1000);

			});

	}
}
