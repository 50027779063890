import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import { NbDatepickerModule, NbIconModule, NbLayoutModule } from '@nebular/theme';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
	declarations: [FooterComponent],
	imports: [CommonModule, NbIconModule, RouterModule, NbLayoutModule, NbDatepickerModule.forRoot(), ReactiveFormsModule],
	exports: [FooterComponent],
})
export class FooterModule {}
