import { NbDialogService, NbDialogRef } from '@nebular/theme';

(() => {
	let scroll = 0;

	// Pollyfills to fix scroll on mobile dialogs
	const open = NbDialogService.prototype.open;

	NbDialogService.prototype.open = function (content: any, userConfig: any) {
		if(document.body.style.position !== 'fixed') {
			scroll = window.scrollY;

			document.body.style.position = 'fixed';
			document.body.style.width = '100vw';
			document.body.style.paddingRight = '15px';
			document.body.style.top = `-${scroll}px`;
		}

		return open.bind(this)(content, userConfig);
	};

	const close = NbDialogRef.prototype.close;

	NbDialogRef.prototype.close = function(res) {
		setTimeout(() => {
			document.body.style.position = '';
			document.body.style.width = '';
			document.body.style.paddingRight = '';
			document.body.style.top = '';

			window.scrollTo(0, scroll);
		}, 0);

		return close.bind(this)(res);
	};
})();