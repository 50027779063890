import { NgModule } from '@angular/core';
import {
	BrowserModule,
	HammerModule,
	HAMMER_GESTURE_CONFIG
} from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
	NbThemeModule,
	NbDatepickerModule,
	NbToastrModule,
	NbDialogModule,
	NbGlobalLogicalPosition,
	NbMenuModule,
	NbAlertModule
} from '@nebular/theme';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { ExpiredTokenInterceptor } from './core/interceptors/expired-token.interceptor';
import { ConstantsService } from './core/services/constant.service';
import { GlobalErrorInterceptor } from './core/interceptors/global-error.interceptor';
import { UnauthorizedInterceptor } from './core/interceptors/unauthorized.interceptor';
import { UrlService } from './core/services/url.service';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { CoreRoutingModule } from './core/core-routing.module';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbDateFnsDateModule } from '@nebular/date-fns';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgwWowModule } from 'ngx-wow';
import { AuthModule } from './auth/auth.module';
import { HammerConfig } from './core/services/hammer.service';

@NgModule({
	declarations: [AppComponent],
	imports: [
		AppRoutingModule,
		BrowserAnimationsModule,
		BrowserModule,
		CoreRoutingModule,
		CoreModule,
		HttpClientModule,
		NbEvaIconsModule,
		DragDropModule,
		NgwWowModule,
		HammerModule,
		NbAlertModule,
		NbThemeModule.forRoot({ name: 'default' }),
		NbDatepickerModule.forRoot(),
		NbToastrModule.forRoot({
			position: NbGlobalLogicalPosition.BOTTOM_END,
			toastClass: 'toast-close'
		}),
		NbDateFnsDateModule.forRoot({
			parseOptions: {
				useAdditionalWeekYearTokens: true,
				useAdditionalDayOfYearTokens: true
			},
			formatOptions: {
				useAdditionalWeekYearTokens: true,
				useAdditionalDayOfYearTokens: true
			}
		}),
		AuthModule,
		NbDialogModule.forRoot(),
		NbMenuModule.forRoot(),
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ExpiredTokenInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: GlobalErrorInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: UnauthorizedInterceptor,
			multi: true
		},
		{
			provide: HAMMER_GESTURE_CONFIG,
			useClass: HammerConfig
		},
		ConstantsService,
		UrlService
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
