import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import Payload from '../core/models/Payload';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import LoginResponse from '../core/models/LoginResponse';
import ForgotPasswordResponse from '../core/models/ForgotPasswordResponse';
import ChangePasswordResponse from '../core/models/ChangePasswordResponse';
import LoginWithTokenResponse from '../core/models/LoginWithTokenResponse';

@Injectable()
export class AuthService {
	endpoint = `${environment.apiEndpoint}/auth`;

	constructor(private http: HttpClient) {
	}

	login(email: string, password: string): Promise<LoginResponse> {
		return this.http.post<Payload<LoginResponse>>(`${this.endpoint}/login`, {
			email,
			password,
		})
			.pipe<LoginResponse>(
				map(response => (response.data)),
			).toPromise();
	}

	loginWithToken(token: string): Promise<LoginWithTokenResponse> {
		return this.http.post<Payload<LoginWithTokenResponse>>(`${this.endpoint}/login/${token}`, {})
			.pipe<LoginWithTokenResponse>(
				map(response => (response.data)),
			).toPromise();
	}



	forgotPassword(email: string): Promise<ForgotPasswordResponse> {
		return this.http.post<Payload<ForgotPasswordResponse>>(`${this.endpoint}/reset-password/${email}`, null)
			.pipe<ForgotPasswordResponse>(
				map(response => (response.data)),
			).toPromise();
	}

	changePassword(token: string, password: string): Promise<ChangePasswordResponse> {
		return this.http.put<Payload<ChangePasswordResponse>>(`${this.endpoint}/reset-password/${token}`, { password })
			.pipe<ChangePasswordResponse>(
				map(response => (response.data)),
			).toPromise();
	}

}
