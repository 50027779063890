import { Component, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BaseComponent } from '../../../library/classes/base.component';
import { AuthService } from '../../auth.service';
import { mapValues } from 'lodash';
import { Subscription } from 'rxjs';
import { NbToastrService } from '@nebular/theme';
import { ActivatedRoute } from '@angular/router';

type Modes = 'login' |
	'forgot-password' |
	'reset-password' |
	'invalid-reset-token' |
	'forgot-sent';

interface RouteData {
	mode: Modes;
}

interface ParamsData {
	token: string;
}

@Component({
	selector: 'app-change-password',
	templateUrl: './change-password.component.html',
	styleUrls: [ './change-password.component.scss' ],
})
export class ChangePasswordComponent extends BaseComponent implements OnDestroy {

	public error: string = null;
	public complete = false;
	private passwordResetToken: string = null;
	private subscriptions: Subscription[] = [];

	public form = new FormGroup({
		password: new FormControl('', [ Validators.required ]),
		confirmPassword: new FormControl('', [ Validators.required ]),
	});

	constructor(
		private authService: AuthService,
		private toastrService: NbToastrService,
		private route: ActivatedRoute,
	) {
		super();

		this.subscriptions.push(route.params.subscribe(( data: ParamsData ) => {
			this.passwordResetToken = data.token;
		}));

	}

	ngOnDestroy() {
		this.subscriptions.forEach(s => {
			s.unsubscribe();
		});
	}

	checkPasswords() {

		const password = this.form.get('password').value.trim();
		const confirmPassword = this.form.get('confirmPassword').value.trim();

		const valid = password.length < 1 || confirmPassword.length < 1 || password.trim() === confirmPassword.trim();

		return valid;

	}

	get f() {
		return this.form.controls;
	}

	async submit() {

		const model = mapValues(this.f, v => v.value);

		const response = await this.authService.changePassword(this.passwordResetToken, model.password);

		this.complete = response.updated;

		if (!this.complete) {
			this.error = 'The link seems expired. You can try sending another link by clicking below.';
		}
	}
}
